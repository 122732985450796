@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-Light.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-Light.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-Light.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-Light.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-LightItalic.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-LightItalic.eot?#iefix')
            format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-LightItalic.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-LightItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-LightItalic.ttf')
            format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-Book.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-Book.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-Book.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-Book.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-BookItalic.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-BookItalic.eot?#iefix')
            format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-BookItalic.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-BookItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-BookItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-Medium.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-Medium.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-Medium.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-Medium.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-Medium.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Headline;
    src: url('./fonts/KoenigBauerTypeHeadline-MediumItalic.eot');
    src: url('./fonts/KoenigBauerTypeHeadline-MediumItalic.eot?#iefix')
            format('eot'),
        url('./fonts/KoenigBauerTypeHeadline-MediumItalic.woff2')
            format('woff2'),
        url('./fonts/KoenigBauerTypeHeadline-MediumItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeHeadline-MediumItalic.ttf')
            format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-Light.eot');
    src: url('./fonts/KoenigBauerTypeText-Light.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeText-Light.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-Light.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-LightItalic.eot');
    src: url('./fonts/KoenigBauerTypeText-LightItalic.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeText-LightItalic.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-LightItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-Medium.eot');
    src: url('./fonts/KoenigBauerTypeText-Medium.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeText-Medium.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-Medium.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-MediumItalic.eot');
    src: url('./fonts/KoenigBauerTypeText-MediumItalic.eot?#iefix')
            format('eot'),
        url('./fonts/KoenigBauerTypeText-MediumItalic.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-MediumItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-MediumItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-SemiBold.eot');
    src: url('./fonts/KoenigBauerTypeText-SemiBold.eot?#iefix') format('eot'),
        url('./fonts/KoenigBauerTypeText-SemiBold.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-SemiBold.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Koenig Bauer Type Text;
    src: url('./fonts/KoenigBauerTypeText-SemiBoldItalic.eot');
    src: url('./fonts/KoenigBauerTypeText-SemiBoldItalic.eot?#iefix')
            format('eot'),
        url('./fonts/KoenigBauerTypeText-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/KoenigBauerTypeText-SemiBoldItalic.woff') format('woff'),
        url('./fonts/KoenigBauerTypeText-SemiBoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
