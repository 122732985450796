*:not(input):not(textarea):not(select) {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.selectable {
    user-select: auto;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#root {
    height: 100%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
}
